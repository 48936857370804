
hr { border-color: $border-color; }
strong { font-weight: $font-weight-semibold; }

.selectize-control.multi .selectize-input [data-value] {
    text-shadow: none !important;
    box-shadow: none !important;
    background-image: none !important;
    background-color: #0168fa !important;
    border-color: #0168fa !important;
}
.selectize-input {
    box-shadow: none !important;
    border: 1px solid rgba(135, 150, 165, 0.15) !important;
}
.table {
    border-radius: .25rem;
}
.table.dataTable thead th {
    padding: 15px 10px;
    line-height: 1.3;
}
.chat-group-divider {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #8392a5;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
}
.chat-group-divider::before, .chat-group-divider::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #e5e9f2;
}
.chat-group-divider::before {
    margin-right: 10px;
}
.chat-group-divider::after {
    margin-left: 10px;
}
.timeline-body.no-dot::before {
    background: none !important;
    border: none !important;
}
.timeline-body.black-dot::before {
    background: #3b4863 !important;
    border: none !important;
}
.timeline-body.black-dot h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    padding-bottom: 25px;
}